import React from 'react';
import classNames from 'classnames';
export default React.memo(function VideoAnthology({
  className,
  onClick
}) {
  return (
    <div onClick={onClick} style={{ lineHeight: "3em" }} className={classNames(className, {
      'video-react-control': true,
      'video-react-button': true
    })}>
      选集
    </div>
  );
})
