import React from 'react';
import { Tabs } from 'antd';
import '../assets/scss/tabs.scss';
const { TabPane } = Tabs;
function CourseTabs({ description, questions }) {
  return (
    <div className="tabs-wrapper">
      <Tabs defaultActiveKey="1">
        <TabPane tab="课程简介" key="1">
          <img src={description} />
        </TabPane>
        <TabPane tab="课程提示" key="2">
          <img src={questions} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default CourseTabs;
