import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Empty, Spin } from 'antd';
import HorizontalItem from '../components/HorizontalItem';
import CourseTabs from '../components/CourseTabs';
import '../assets/scss/detail.scss';
import empty from '../assets/image/empty.png';
import { httpPost } from '../config/fetch';
import { getCourseDetail, getVideoList } from '../config/api';
import { showRedeemCodeStatus, showScanToPayStatus } from '../state/actions';
import { orderBy } from 'lodash';
const DetailPage = props => {
  const {
    showRedeemCodeStatus,
    scanTopayStatus,
    showScanToPayStatus,
    userInfo,
    location,
    userCourseList
  } = props;
  const [courseInfo, setCourseInfo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const courseId = location.search.split('?')[1];

  const getUserVideoList = params => {
    httpPost(getVideoList, params).then(res => {
      const { resCode, videoList } = res;
      let list = [];
      if (resCode === 0) {
        list = orderBy(videoList, ['videoId'], ['asc']);
      }
      setVideos(list);
    });
  };

  useEffect(() => {
    const getData = () => {
      const params = {
        courseId
      };
      setLoading(true);
      httpPost(getCourseDetail, params)
        .then(res => {
          const { resCode } = res;
          if (resCode === 0) {
            const userCourse = userCourseList.find(
              item => item.courseId === courseId
            );
            userCourse &&
              getUserVideoList({ courseId, userEmail: userInfo.email });
            setCourseInfo({ ...res, ...userCourse });
          } else if (resCode === 8) {
            setCourseInfo(null);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setCourseInfo(null);
        });
    };
    getData();
  }, [courseId, userCourseList]);
  return (
    <Layout courseId={courseId}>
      <SEO title="课程详情页" />
      <div className="detail-wrapper">
        <div className="container">
          <Spin spinning={loading} size={'large'}>
            {courseInfo ? (
              <Fragment>
                <HorizontalItem
                  showRedeemCodeStatus={showRedeemCodeStatus}
                  showScanToPayStatus={showScanToPayStatus}
                  scanTopayStatus={scanTopayStatus}
                  userInfo={userInfo}
                  {...courseInfo}
                  videoInfo={videos}
                />
                <CourseTabs
                  description={courseInfo.description}
                  questions={courseInfo.questions}
                />
              </Fragment>
            ) : (
              <Empty
                className="empty-wrapper"
                image={empty}
                description={<span className="text">未找到相关内容</span>}
              />
            )}
          </Spin>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    redeemCodeStatus: state.redeemCodeStatus,
    scanTopayStatus: state.scanTopayStatus,
    loginStatus: state.loginStatus,
    userInfo: state.userInfo,
    userCourseList: state.userCourseList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    showRedeemCodeStatus: status => {
      dispatch(showRedeemCodeStatus(status));
    },
    showScanToPayStatus: status => {
      dispatch(showScanToPayStatus(status));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
