import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlayToggle,
  CurrentTimeDisplay,
  TimeDivider,
  VolumeMenuButton
} from 'video-react';
import 'video-react/dist/video-react.css';
import '../assets/scss/video.scss';
import VideoTitle from './video-title';
import VideoAnthology from './video-anthology';
import VideoList from './video-list';
import { setReadVideoInfo } from '../state/actions';
import { head } from 'lodash';

function Mask({ onClick }) {
  return <div className="mask" onClick={onClick} />;
}

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hasStarted: false,
      title: '',
      id: 0,
      currentTime: 0,
      path: '',
      pic: ''
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  componentDidMount() {
    const { videoInfo, readVideoInfo, courseId } = this.props;
    this.player.subscribeToStateChange(this.handleStateChange);
    let currentTime = 0;
    let id = null;
    const video = readVideoInfo[courseId];
    if (readVideoInfo && video) {
      id = video.id;
      currentTime = video.currentTime;
    }
    let singleVideoInfo = videoInfo.find(item => item.videoId === id);
    if (!singleVideoInfo) {
      singleVideoInfo = head(videoInfo);
      currentTime = 0;
    }
    const { videoId, videoName, videoUrl } = singleVideoInfo;
    this.setState({
      title: videoName,
      id: videoId,
      currentTime,
      path: videoUrl
    });
  }

  componentWillUnmount() {
    this.saveVideoInfo();
  }

  handleStateChange = (state, prevState) => {
    const { hasStarted, ended } = state;
    if (ended) {
      this.playVideo();
    }
    if (hasStarted) {
      this.saveVideoInfo();
    }
    this.setState({
      hasStarted
    });
  };

  playVideo = () => {
    const { videoInfo } = this.props;
    let { id } = this.state;
    const videoInfoListLength = videoInfo.length;
    if (id === videoInfo[videoInfoListLength - 1].videoId) return;
    let newVideoId = ++id;
    const { videoId, videoName, videoUrl } = videoInfo.find(
      item => item.videoId === newVideoId
    );
    this.setState({
      title: videoName,
      id: videoId,
      currentTime: 0,
      path: videoUrl
    });
    this.player.load();
    setTimeout(() => {
      this.player.play();
    });
  };

  saveVideoInfo = () => {
    const { id, hasStarted } = this.state;
    const { readVideoInfo, setReadVideoInfo, courseId } = this.props;
    if (!hasStarted) return;
    const { player } = this.player.getState();
    const { currentTime, duration } = player;
    let newVideoInfo = {};
    if (readVideoInfo) {
      newVideoInfo = readVideoInfo;
      newVideoInfo[courseId] = {
        currentTime,
        duration,
        id
      };
    } else {
      newVideoInfo[courseId] = {
        currentTime,
        duration,
        id
      };
    }
    setReadVideoInfo(newVideoInfo);
  };

  changeVideoUrl = video => {
    const { id: currentId } = this.state;
    const { videoName, videoId, videoUrl } = video;
    if (currentId === videoId) return;
    this.saveVideoInfo();
    this.setState({
      title: videoName,
      id: videoId,
      currentTime: 0,
      path: videoUrl
    });
    this.player.load();
    setTimeout(() => {
      this.player.play();
    });
    this.onClose();
  };

  historyPlayBack = () => {
    const { currentTime } = this.state;
    currentTime !== 0 && message.info('已为您跳转到上次观看位置');
    this.player.play();
    this.player.seek(currentTime);
  };

  render() {
    const { videoInfo, pic } = this.props;
    const { path, visible, title, id } = this.state;
    return (
      <div className="video-wrapper">
        <Player
          ref={player => {
            this.player = player;
          }}
          aspectRatio={'16:9'}
          preload="auto"
          poster={pic}
        >
          <source src={path} type="video/mp4" />
          <VideoList
            id={id}
            visible={visible}
            onClose={this.onClose}
            list={videoInfo}
            handleChange={this.changeVideoUrl}
          />
          <Mask onClick={this.historyPlayBack} />
          <VideoTitle title={title} />
          <BigPlayButton
            position="center"
            actions={{
              play: this.historyPlayBack
            }}
          />
          <ControlBar>
            <PlayToggle />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={3.2} />
            <VideoAnthology onClick={this.showDrawer} order={7} />
            <VolumeMenuButton order={7.1} />
          </ControlBar>
        </Player>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    readVideoInfo: state.readVideoInfo
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setReadVideoInfo: status => {
      dispatch(setReadVideoInfo(status));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
