import React, { Fragment, useState, memo } from 'react';
import { message } from 'antd';
import Video from './Video';
import '../assets/scss/horizontal-item.scss';
import { EXCHANGE_TYPE } from '../utils';
function HorizontalItem({
  courseId,
  courseName,
  introduction,
  price,
  tools,
  cover,
  method,
  showRedeemCodeStatus,
  userInfo,
  videoInfo
}) {
  const redeemCode = () => {
    if (!userInfo.email) {
      return message.info(`请先登录账号`);
    }
    showRedeemCodeStatus(true);
  };

  return (
    <Fragment>
      <div className="horizontal-item-wrapper">
        <div className="left-wrapper">
          {method === EXCHANGE_TYPE && videoInfo.length > 0 ? (
            <Video pic={cover} courseId={courseId} videoInfo={videoInfo} />
          ) : (
            <img className="image" src={cover} alt="" />
          )}
        </div>
        <div className="right-wrapper">
          <div className="desc">
            <div>
              <p className="title">{courseName}</p>
              <p className="text">{introduction}</p>
              <p className="text">
                <span>准备道具：</span>
                {tools}
              </p>
            </div>
            {!method && (
              <div>
                <p className="price">￥{price}</p>
                <div className="btns">
                  <span className="btns-item convert" onClick={redeemCode}>
                    兑换课程
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default memo(HorizontalItem);
