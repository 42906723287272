import React from 'react';
export default React.memo(function VideoTitle({
  title,
  player
}) {
  const { hasStarted, paused, userActivity } = player
  return (
    hasStarted &&
    <div
      className={
        `video-wrapper-title  ${(userActivity || paused) ? 'show' : 'hide'}`
      }
    >
      {title}
    </div>
  );
})
