import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { UnorderedListOutlined } from '@ant-design/icons';
import small_play_icon_h from '../assets/image/small_play_icon_h.png';
const DEFAULT_WIDTH = 200;
const FULL_SCREEN_WIDTH = 380;
export default React.memo(function VideoList({
  visible,
  onClose,
  handleChange,
  list,
  id,
  className,
  player
}) {
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const handleClick = (e, item) => {
    e.stopPropagation();
    handleChange(item);
  };
  useEffect(() => {
    player.isFullscreen ? setWidth(FULL_SCREEN_WIDTH) : setWidth(DEFAULT_WIDTH);
  }, [player]);
  useEffect(() => {
    if (visible) {
      const drawerBody = document.querySelector(
        '.video-list-wrapper .ant-drawer-body'
      );
      const activeElement = drawerBody.querySelector('.content-list.active');
      const scrollHeight = activeElement.scrollHeight;
      drawerBody.scrollTop = scrollHeight * (id > 0 ? id - 1 : id);
    }
  }, [visible]);
  return (
    visible && (
      <Drawer
        width={width}
        className="video-list-wrapper"
        title={
          <div className="title-wrapper">
            <UnorderedListOutlined className="icon" />
            <span className="text">课程章节</span>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        getContainer={false}
      >
        <div className="content-wrapper">
          {list.map(item => {
            return (
              <p
                key={item.videoId}
                className={classNames(className, {
                  'content-list': true,
                  active: item.videoId === id
                })}
                onClick={e => handleClick(e, item)}
              >
                <span className="txt">{item.videoName}</span>
                {item.videoId === id && (
                  <img src={small_play_icon_h} alt="play_icon" />
                )}
              </p>
            );
          })}
        </div>
      </Drawer>
    )
  );
});
